(function () {
    angular.module('informaApp')
        .controller('HomeController', HomeController);

    function HomeController($rootScope, $scope, ConstantsSvc, TooltipsService,
                            TableGroupsRoutes, RequestQueueHelper, FilterService,
                            SavedSearchesCrossData, DownloadService, ChartService,
                            ModalHelper, DrugIndicationMapper, BiomedtrackerUrlBuilder,
                            ChartMapper, HashService, NavigatorService,
                            ChartTypesService, OmnitureSvc, SaveSearchSvc,
                            $routeParams, $q, ExportReferrerTypes, BarChartMapper, DrugIndicationService, DrugHideSvc, AuthenticationService) {

        $scope.loadingModalId = "loadingModal";

        $scope.includeImages = true;

        $scope.hiddenPhases = [];
        $scope.itemsToCompare = [];

        $scope.tooltips = null;
        $scope.filter = null;

        $scope.resultsCount = {
            count: 0,
            totalCount: 0
        };

        $scope.scrollToTop = function () {
            $('html').animate({
                scrollTop: 0
            }, 500);
        };

        $scope.tableDurationIsMean = true;
        $rootScope.$on("durationIsMedian", () => {$scope.tableDurationIsMean = false});
        $rootScope.$on("durationIsMean", () => {$scope.tableDurationIsMean = true});

        $scope.filterActions = {};

        $scope.onTableEmpty = function () {
            NavigatorService.openTable();
        };

        TooltipsService.getAllTooltipsAsObject()
            .then(function (data) {
                $scope.tooltips = data;
            });

        $scope.onFilterChange = onFilterChange;

        $scope.requestQueues = {
            filterCount: RequestQueueHelper.getQueue(),
            filter: RequestQueueHelper.getQueue()
        };

        $scope.recalculateResultsCount = function () {
            addRequestInQueue(recalculateResultCount, $scope.requestQueues.filterCount);
        };

        $scope.resetFilter = resetFilter;

        FilterService.getResultCount(getDefaultFilter()).then(setTotalCount);

        $scope.onFilterReady = onFilterReady;

        $scope.getSavedSearchData = getSavedSearchData;

        $scope.urls = ConstantsSvc.urls;

        $scope.comapnyProfileTabVisible = false;
        $scope.ganttChartTabVisible = false;
        $scope.trendsViewTabVisible = false;
        $scope.chartViewTabVisible = false;
        $scope.predictiveAnalyticsTabVisible = false;

        setGroups($scope, ConstantsSvc, FilterService, DownloadService, ChartService, TableGroupsRoutes);
        setDropdownSources($scope, ConstantsSvc, makeBarsChart, addToComparison, toggleGroup);

        $scope.toggleGroup = toggleGroup;

        $scope.togglePhase = togglePhase;

        $scope.generateIsNotAllowed = function () {
            return $scope.resultsCount.count == 0;
        };

        $scope.selectedTableItems = [];

        $scope.makeBarsChart = makeBarsChart;

        $scope.showHistoricalAnalytics = function () {
            NavigatorService.openTable();
            OmnitureSvc.trackDashboardTabName('Historical Analytics');
        };

        $scope.clickOnPredictiveAnalytics = function () {
            NavigatorService.openPredictive();
            OmnitureSvc.trackDashboardTabName('Predictive Analytics');
        };

        $scope.clickOnCompanyProfileTab = function () {
            $scope.tableRow.phase = null;
            NavigatorService.openCompanyProfile();
            OmnitureSvc.trackDashboardTabName('Company Profile');
        };

        $scope.clickOnChartViewTab = function () {
            $scope.chartViewData = null;
            $scope.chartViewTabVisible = true;

            NavigatorService.openChart();
            OmnitureSvc.trackDashboardTabName('Chart View');
        };

        $scope.addToComparison = addToComparison;
        $scope.clickOnComparison = clickOnComparison;

        $scope.tableLoaded = function () {
            ModalHelper.hideModal('#' + $scope.loadingModalId)
        };

        $scope.clickOnDuration = clickOnDuration;

        $scope.generateTable = function () {
            if ($scope.generateIsNotAllowed()) {
                return;
            }

            if (!$scope.routeStartsWith($scope.urls.table)) {
                NavigatorService.openTable();
                return;
            }

            addRequestInQueue(generateTable, $scope.requestQueues.filter);

            $scope.hiddenDrugs = {};
        };

        $scope.clickOnGanttChartTab = function () {
            $scope.ganttChartData = null;

            NavigatorService.openGanttChart();
            OmnitureSvc.trackDashboardTabName('Timeline View')
        };

        $scope.clickOnSummaryView = () => {
            $scope.summaryViewData = null;
            NavigatorService.openSummaryView();
            OmnitureSvc.trackDashboardTabName('Summary View')
        };

        $scope.clickOnTrendsViewTab = () => {
            $scope.trendsViewProfileData = null;
            $scope.trendsViewTabVisible = true;

            NavigatorService.openTrendsView();
            OmnitureSvc.trackDashboardTabName('Trends View');
        };

        $scope.showTrendsVewProfile = (selectedItems = null, filter = null) => {
            $scope.trendsViewTabVisible = true;
            $scope.trendsViewProfileData = {
                selectedItems: selectedItems || _.clone($scope.selectedTableItems),
                filter
            };

            OmnitureSvc.trackTableAction('Trends View');
            NavigatorService.openTrendsView();
        };

        $scope.onSummaryViewShown = () => {
            $scope.summaryViewTabVisible = true;
        };

        $scope.onTrendsViewShown = () => {
            $scope.trendsViewTabVisible = true;
        };

        $scope.onChartViewShown = () => {
            $scope.chartViewTabVisible = true;
        };

        $scope.onSummaryViewEmpty = function () {
            NavigatorService.openTable();
            $scope.summaryViewTabVisible = false;
        };

        $scope.onTrendsViewEmpty = function () {
            NavigatorService.openTable();
            $scope.trendsViewTabVisible = false;
        };

        $scope.onChartViewEmpty = function () {
            NavigatorService.openTable();
            $scope.chartViewTabVisible = false;
        };

        $scope.onCompanyProfileEmpty = function () {
            NavigatorService.openTable();
            $scope.comapnyProfileTabVisible = false;
        };

        $scope.onGanttChartEmpty = function () {
            NavigatorService.openTable();
            $scope.ganttChartTabVisible = false;
        };

        $scope.onRegulatoryChange = function (name) {
            if (name) {
                OmnitureSvc.trackRegulatory(name)
            }
        };

        $scope.onTimeframeChange = function (timeframe) {
            function processDate(date) {
                return moment(date).format(ConstantsSvc.Dates.queryFormat);
            }

            OmnitureSvc.trackFilter('Timeframe', processDate(timeframe.startDate) + " - " + processDate(timeframe.endDate));
        }

        $scope.onModalChange = function (name, data) {
            var map = {
                diseases: 'Diseases',
                companies: 'Companies',
                drugClasses: 'Drug Classes',
                molecules: 'Molecules',
                targets: 'Targets',
                drugs: 'Drugs',
                routes: 'Routes'
            };

            var source = data[0];

            var trackParams = source.length ? source.map(function (x) {
                return x.name
            }).join(', ') : 'All';

            OmnitureSvc.trackFilter(map[name], trackParams);
        };

        $scope.downloadDataset = function (includeImages) {
            var route = $scope.groups.source[$scope.groups.active].route;

            downloadData(DownloadService.downloadDataset, "datasetIsDownloading", {
                includeImages: includeImages,
                route: route
            });

            OmnitureSvc.trackDownload('dataset');
        };

        $scope.exportTable = function () {
            downloadData($scope.groups.source[$scope.groups.active].exportTable, "tableIsExporting");

            OmnitureSvc.trackDownload('table');
        };
        
        $scope.qlsDrugDetailExportTable = function (indicationId, drugId) {
            downloadQLSDrugDetailData(DownloadService.downloadQLSDrugDetail, "tableIsExporting", indicationId, drugId);

            OmnitureSvc.trackDownload('qls-drug-detail');
        };

        $scope.onSelectedItemsChange = onSelectedItemsChange;

        $scope.onClickAddToComparison = (row) => {
            const itemToCompare = _.clone($scope.tableSource.filter(x => x.id === row.id));

            addToComparison(itemToCompare)
        };

        $scope.getItemsToCompare = () => getItemsForExport($scope.itemsToCompare);

        $scope.onClickShowSummaryView = (data) => {
            $scope.summaryViewData = data;

            NavigatorService.openSummaryView();
        };

        $scope.summaryNavigationPanelCallbacks = {
            barChartClick: (item, settings) => {
                const chartSetting = settings
                    ? { metric: settings.metrics }
                    : undefined;

                makeBarsChart([item], chartSetting);
            },
            trendsViewClick: (item, settings) => {
                const chartSetting = settings
                    ? {
                        metric: settings.metrics,
                        yearRange: settings.range,
                        inverted: settings.inverted
                    }
                    : undefined;

                $scope.showTrendsVewProfile([item], chartSetting);
            },
            ganttChartClick: (item, settings) => {
                const {id, name, drugIndicationIds} = item;

                clickOnDuration(id, name, drugIndicationIds, settings && settings.durationMode);
            },
            boxPlotChartClick: (item) => {
                makeBarsChart([item], {isBoxPlot: true});
            },
            companyProfileClick: (item) => {
                const {id, name, drugIndicationIds} = item;

                clickOnLoa(id, name, 0, drugIndicationIds);
            },
            drugLevelClick: (item) => {
                const {id, name, drugIndicationIds} = item;

                openDrugLevelPopup(id, name, 0, drugIndicationIds);
            }
        };

        $scope.drugLevelPopupOptions = {};        
        $scope.openQLSPopup = openQLSPopup;

        $scope.qlsPopupOptions = {};
        $scope.openDrugLevelPopup = openDrugLevelPopup;        

        $scope.onTrendsViewFilterChanges = (filter) => $scope.trendsViewFilter = filter;
        $scope.onChartViewFilterChanges = (filter) => $scope.chartViewFilter = filter;

        $scope.onGanttDataChanges = onGanttDataChanges;
        $scope.onAddSavedSearchToComparisonClicked = onAddSavedSearchToComparisonClicked;
        $scope.onStartLoadingSearch = onStartLoadingSearch;

        function onStartLoadingSearch() {
            ModalHelper.showLoadingModal('#' + $scope.loadingModalId);
        }

        function onAddSavedSearchToComparisonClicked(data) {
            $scope.itemsToCompare.push(...mapTableData(data));
        }

        function toggleGroup(group) {
            if ($scope.generateIsNotAllowed()) {
                return;
            }

            for (var i = 0; i < $scope.groups.source.length; i++) {
                if ($scope.groups.source[i] == group) {
                    if ($scope.groups.active == i) {
                        return;
                    }

                    $scope.groups.active = i;
                    break;
                }
            }

            if ($scope.result) {
                $scope.generateTable();
            }
        }

        function togglePhase(phaseNumber) {
            const maxPhasesCount = ConstantsSvc.phasePropertyNames.length;
            const isPhaseHidden = $scope.hiddenPhases.includes(phaseNumber);

            if (!isPhaseHidden && $scope.hiddenPhases.length === maxPhasesCount - 1) {
                return;
            }

            $scope.hiddenPhases = isPhaseHidden
                ? $scope.hiddenPhases.filter(x => x !== phaseNumber)
                : [phaseNumber, ...$scope.hiddenPhases];

            OmnitureSvc.trackPhase(phaseNumber);
        }

        function setTotalCount(response) {
            $scope.resultsCount.totalCount = +response.data.data;
        }

        function resetFilter() {
            OmnitureSvc.trackResetSelections();
            $scope.filterActions.resetFilter();
        }

        function recalculateResultCount(callback) {
            var filter = $scope.filter;

            FilterService.getResultCount(filter).then(function (response) {
                $scope.resultsCount.count = +response.data.data;

                if (callback) callback();
            });
        }

        function addRequestInQueue(request, queue) {
            queue.addRequest(request);
        }

        function onFilterChange(filter) {
            $scope.filter = filter;

            $scope.recalculateResultsCount();
        }

        function getDefaultFilter() {
            return {
                startDate: ConstantsSvc.Dates.start,
                endDate: ConstantsSvc.Dates.end,
            };
        }
        
        function openQLSPopup(qlsInfo, showingPots) {
            if (qlsInfo) {
                $scope.qlsPopupSource = {
                    qlsInfo: qlsInfo,                   
                    poaData: {
                        estimate: qlsInfo.POAEstimate,
                        baseline: qlsInfo.POABaseline,
                        feature: qlsInfo.POAFeature,
                        otherFeaturesInfluence: qlsInfo.POAOtherFeaturesInfluence,
                        otherFeaturesRawInfluence: qlsInfo.POAOtherFeaturesRawInfluence,
                
                
                    },
                    potsData: {
                        estimate: qlsInfo.POTSEstimate,
                        baseline: qlsInfo.POTSBaseline,
                        feature: qlsInfo.POTSFeature,
                        otherFeaturesInfluence: qlsInfo.POTSsOtherFeaturesInfluence,
                        otherFeaturesRawInfluence: qlsInfo.POTSOtherFeaturesRawInfluence
                    },
                    showingPots: showingPots,
                    exportSource: (callback) => {
                        const ids = drugIndicationIds.shown.concat(qlsInfo.drugIndicationIds);
                        DrugIndicationService.exportExcel(ids, $scope.filter.startDate, $scope.filter.endDate, callback);
                    }
                };
    
                $scope.qlsPopupOptions.show();
            }
        }

        function openDrugLevelPopup(id, name, phase, drugIndicationIds) {
            if (!$scope.hiddenDrugs[id]) {
                $scope.hiddenDrugs[id] = [];
            }

            $scope.drugLevelPopupSource = {
                getSource: generateDrugIndicationSourceFunction(drugIndicationIds),

                exportSource: (callback) => {
                    const ids = drugIndicationIds.shown.concat(drugIndicationIds.hidden);
                    DrugIndicationService.exportExcel(ids, $scope.filter.startDate, $scope.filter.endDate, callback);
                },

                phase,
                title: name,
                updateDrugs: getUpdateDrugsFunction(id),
                hiddenDrugsForItem: $scope.hiddenDrugs[id]
            };

            $scope.drugLevelPopupOptions.show();
        }

        function getUpdateDrugsFunction(itemId) {
            return (hidden) => {
                return DrugHideSvc.updateDrugs(itemId, hidden)
                    .then(function (res) {
                        $scope.hiddenDrugs[itemId] = hidden;

                        const item = $scope.tableSource.find(x => x.id === itemId);

                        res.link = item.link ? item.link : res.link;

                        $scope.tableSource[$scope.tableSource.indexOf(item)] = res;
                        $rootScope.$broadcast('drugsHidden', hidden, $scope.tableSource);
                    });
            }
        }

        function generateDrugIndicationSourceFunction(ids) {
            return function (callback) {
                DrugIndicationService.getDrugIndications({
                    ids: ids.shown.concat(ids.hidden),
                    startDate: $scope.filter.startDate,
                    endDate: $scope.filter.endDate
                }).then(callback);
            }
        }

        function applySavedSearch() {
            const {data} = SavedSearchesCrossData;

            if (data != null) {
                $scope.filterActions.applyFilter(data.filter);

                $scope.groups.active = data.activeTableGroupIndex || 0;
                $scope.hiddenDrugs = data.hiddenDrugs || {};

                $scope.tableDurationIsMean = data.tableDurationIsMean === undefined ? true : data.tableDurationIsMean;

                if (data.itemsToCompare) {
                    $scope.itemsToCompare.push(...mapTableData(data.itemsToCompare));
                }

                if (!data.referrerType) {
                    addRequestInQueue(generateTable, $scope.requestQueues.filter);
                }

                if (data.referrerType === ExportReferrerTypes.ganttChart) {
                    const {id, name, drugIndicationIds, isDurationChart} = data.chartData;
                    clickOnDuration(id, name, {shown: drugIndicationIds}, isDurationChart);
                }

                if (data.referrerType === ExportReferrerTypes.chartView) {
                    const {items, metricIndex, isBoxPlotChart} = data.chartData;

                    const mappedChartItems = mapTableData(items);

                    makeBarsChart(mappedChartItems, {
                        metric: metricIndex,
                        isBoxPlot: isBoxPlotChart
                    });

                    $scope.tableSource = null;
                }

                if (data.referrerType === ExportReferrerTypes.trendsView) {
                    const {items, metricIndex: metric, yearIndex: yearRange, inverted} = data.chartData;

                    $scope.showTrendsVewProfile(fillItemsWithGroups(items), {metric, yearRange, inverted})
                    $scope.tableSource = null;
                }
            }
        }

        function fillItemsWithGroups(items) {
            return items.map(x => {
                return _.merge({}, x, {group: $scope.groups.source[x.groupIndex]})
            });
        }

        function getCurrentChartData() {
            if ($scope.isActive($scope.urls.ganttChart)) {
                return {
                    referrerType: ExportReferrerTypes.ganttChart,
                    data: {
                        drugIndicationIds: $scope.ganttChartReceivedData.drugIndicationIds,
                        id: $scope.ganttChartReceivedData.id,
                        name: $scope.ganttChartReceivedData.name,
                        isDurationChart: $scope.ganttChartFilter.isDurationChart
                    }
                };
            }

            if ($scope.isActive($scope.urls.chart)) {
                return {
                    referrerType: ExportReferrerTypes.chartView,
                    data: {
                        metricIndex: $scope.chartViewFilter.metric,
                        isBoxPlotChart: $scope.chartViewFilter.isBoxPlot,
                        items: getItemsForExport($scope.chartViewData.source)
                    }
                };
            }

            if ($scope.isActive($scope.urls.trendsView)) {
                return {
                    referrerType: ExportReferrerTypes.trendsView,
                    data: {
                        metricIndex: $scope.trendsViewFilter.metric,
                        yearIndex: $scope.trendsViewFilter.yearRange,
                        inverted: $scope.trendsViewFilter.inverted,
                        items: $scope.trendsViewProfileData.selectedItems
                    }
                };
            }
        }

        function onGanttDataChanges(ganttChartReceivedData) {
            $scope.ganttChartReceivedData = ganttChartReceivedData;
        }

        function getFilterWithNames() {
            return $scope.filterActions.getFilterWithNames();
        }

        function generateTable(callback) {
            var groupIndex = $scope.groups.active;

            var request = $scope.groups.source[groupIndex].serviceAction($scope.filter);

            $scope.recalculateResultsCount();

            OmnitureSvc.trackGenerate(getFilterWithNames());

            ModalHelper.showLoadingModal("#" + $scope.loadingModalId, function () {

                request.then(function (response) {
                    const isCompany = $scope.groups.source[groupIndex].name === ConstantsSvc.tableGroupsName.company.name;

                    $scope.result = mapTableData(response.data.data, groupIndex, $scope.resultsCount.count);
                    $scope.result.forEach(x => x.isCompany = isCompany);

                    $scope.selectedTableItems.length = 0;
                    $scope.tableSource = _.merge([], $scope.result);

                    if ($scope.groups.source[groupIndex].name == ConstantsSvc.tableGroupsName.company.name) {
                        $scope.clickOnLoa = clickOnLoa;
                    } else {
                        $scope.clickOnLoa = null;
                    }

                    callback();
                });
            });
        }

        function mapTableData(tableData, groupIndex = null, totalPrograms = null) {
            const mappedData = DrugIndicationMapper.mapArray(tableData);
            
            var POTSEstimateExists = 0;

            FillLinksInTableSource(mappedData);

            mappedData.forEach((x, i) => {
                x.groupIndex = groupIndex === null || groupIndex === undefined ? tableData[i].groupIndex : groupIndex;
                x.group = $scope.groups.source[x.groupIndex];
                x.filter = _.merge($scope.filter, {});
                x.totalPrograms = totalPrograms || x.totalPrograms;
                POTSEstimateExists += x.POTSEstimate.reduce((sum, value) => sum + value, 0);

                bindClickOnLoa(x);
            });

             // Check if there's POTS data in the selection and show the Predictive Analytics tab, only if
             $scope.predictiveAnalyticsTabVisible = !!POTSEstimateExists;

            return mappedData;
        }

        function bindClickOnLoa(item) {
            const isCompany = $scope.groups.source[item.groupIndex].name === ConstantsSvc.tableGroupsName.company.name;

            item.clickOnLoa = isCompany ? clickOnLoa : null;
        }

        function clickOnLoa(id, name, phase, indicationsIds) {
            $scope.comapnyProfileTabVisible = true;

            NavigatorService.openCompanyProfile();
            $scope.tableRow = {
                drugIndicationsIds: indicationsIds,
                phase: phase,
                companyId: id,
                companyName: name
            };
        }

        function clickOnDuration(id, name, indicationsIds, isDurationChart = false) {
            $scope.ganttChartTabVisible = true;

            $scope.ganttChartData = {
                drugIndicationsIds: indicationsIds,
                id: id,
                name: name
            };

            $scope.ganttChartFilter = {
                isDurationChart
            }

            NavigatorService.openGanttChart();
        }

        function FillLinksInTableSource(data) {
            var name = $scope.groups.source[$scope.groups.active].name;

            if (name === ConstantsSvc.tableGroupsName.indication.name) {
                setLinks(data, BiomedtrackerUrlBuilder.buildIndicationUrl);
            }

            if (name === ConstantsSvc.tableGroupsName.company.name) {
                setLinks(data, BiomedtrackerUrlBuilder.buildCompanyUrl);
            }
        }

        function setLinks(data, build) {
            for (var i = 0; i < data.length; i++) {
                data[i].link = build(data[i].id);
            }
        }

        function makeBarsChart(data = null, chartFilter = null) {
            $scope.chartViewTabVisible = true;
            $scope.chartViewData = {
                source: data || _.clone($scope.selectedTableItems),
                filter: chartFilter
            };

            OmnitureSvc.trackTableAction('Summary View');

            NavigatorService.openChart();
        }

        function addToComparison(compareItems = _.clone($scope.selectedTableItems)) {
            compareItems.forEach(function (item) {
                item.selected = false;
                item.shownIdsHash = HashService.getHashFromIntArray(item.drugIndicationIds.shown);
                item.hiddenIdsHash = HashService.getHashFromIntArray(item.drugIndicationIds.hidden);
                var isItemPresent = $scope.itemsToCompare.some(function (storedItem) {
                    return item.shownIdsHash === storedItem.shownIdsHash && item.hiddenIdsHash === storedItem.hiddenIdsHash;
                });

                if (!isItemPresent) {
                    $scope.itemsToCompare.push(item);
                }
            });

            OmnitureSvc.trackTableAction('Comparison View');

            NavigatorService.openComparison();
        }

        function clickOnComparison() {
            NavigatorService.openComparison();
            OmnitureSvc.trackDashboardTabName('Comparison');
        }

        function downloadData(action, waitePropName, params) {
            if ($scope[waitePropName]) {
                return;
            }

            $scope[waitePropName] = true;

            const searchData = getSavedSearchData();

            action({searchData, params}, function () {
                $scope[waitePropName] = false;
            });
        }

        
        function downloadQLSDrugDetailData(action, waitePropName, indicationId, drugId) {
            if ($scope[waitePropName]) {
                return;
            }
            $scope[waitePropName] = true;
            
            var params = {};
            var filters = $scope.filterActions.getFilterWithNames();

            filters.indications = [{
                id: indicationId
            }];
            filters.drugs = [{
                id: drugId
            }];
            
            const searchData =  {
                search: {
                    filter: _.merge({}, filters)
                }
            }

            action({searchData, params}, function () {
                $scope[waitePropName] = false;
            });
        }

        function onFilterReady() {
            if ($routeParams.searchUuid) {
                SaveSearchSvc.getSearchBySharedLinkUuid($routeParams.searchUuid).then(function (respose) {
                    SavedSearchesCrossData.data = respose;
                    applySavedSearch();
                });

                return;
            }

            applySavedSearch();
        }

        function onSelectedItemsChange(selectedItems) {
            $scope.selectedTableItems = selectedItems;
        }

        function getSavedSearchData() {
            const filter = getFilterWithNames();

            const chartData = getCurrentChartData() || {};

            return  {
                referrerType: chartData.referrerType,
                search: {
                    filter: _.merge({}, filter),
                    tableDurationIsMean: $scope.tableDurationIsMean,
                    itemsToCompare: $scope.getItemsToCompare(),
                    chartData: chartData.data,
                    hiddenDrugs: $scope.hiddenDrugs,
                    activeTableGroupIndex: $scope.groups.active
                }
            };
        }

        function getItemsForExport(source) {
            if (!source) {
                return [];
            }

            return source.map(x => {
                return {
                    drugIndicationIds: x.drugIndicationIds,
                    groupIndex: x.groupIndex,
                    name: x.name,
                    filter: x.filter,
                    id: x.id
                };
            });
        }
    }

    function setGroups($scope, ConstantsSvc, FilterService, DownloadService, ChartService, TableGroupsRoutes) {
        $scope.changeFilterForCharts = {
            noop: function (filter, selectedItems) {
                return _.merge(filter, {});
            },
            disease: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.diseases);
            },
            subDisease: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.subDiseases);
            },
            indications: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.indications);
            },
            company: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.companies);
            },
            drugClasses: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.drugClasses);
            },
            molecule: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.molecules);
            },
            target: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.targets);
            },
            drug: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.drugs);
            },
            route: function (filter, selectedItems) {
                return changeFilterForCharts(filter, selectedItems, TableGroupsRoutes.routes);
            }
        };

        function changeFilterForCharts(filter, selectedItems, key) {
            var result = _.merge(filter, {});
            result[key] = getIds(selectedItems);

            return result;
        }

        function getIds(items) {
            return items.map(function (x) {
                return x.id;
            });
        }

        $scope.groups = {
            active: 0,
            source: [
                {
                    name: ConstantsSvc.tableGroupsName.allData.name,
                    activeName: ConstantsSvc.tableGroupsName.allData.alternative,
                    serviceAction: FilterService.getResultByAll,
                    exportTable: DownloadService.downloadAll,
                    changeFilterForCharts: $scope.changeFilterForCharts.noop,
                    makeChart: ChartService.trendView.allData,
                    route: TableGroupsRoutes.all
                },
                {
                    name: ConstantsSvc.tableGroupsName.diseaseGroup.name,
                    activeName: ConstantsSvc.tableGroupsName.diseaseGroup.alternative,
                    serviceAction: FilterService.getResultByDisease,
                    exportTable: DownloadService.downloadDiseases,
                    changeFilterForCharts: $scope.changeFilterForCharts.disease,
                    makeChart: ChartService.trendView.byDisease,
                    route: TableGroupsRoutes.diseases
                },
                {
                    name: ConstantsSvc.tableGroupsName.subDiseaseGroup.name,
                    activeName: ConstantsSvc.tableGroupsName.subDiseaseGroup.alternative,
                    serviceAction: FilterService.getResultBySubDisease,
                    exportTable: DownloadService.downloadSubDiseases,
                    changeFilterForCharts: $scope.changeFilterForCharts.subDisease,
                    makeChart: ChartService.trendView.bySubDisease,
                    route: TableGroupsRoutes.subDiseases
                },
                {
                    name: ConstantsSvc.tableGroupsName.indication.name,
                    activeName: ConstantsSvc.tableGroupsName.indication.alternative,
                    serviceAction: FilterService.getResultByIndication,
                    exportTable: DownloadService.downloadIndications,
                    changeFilterForCharts: $scope.changeFilterForCharts.indications,
                    makeChart: ChartService.trendView.byIndications,
                    route: TableGroupsRoutes.indications
                },
                {
                    name: ConstantsSvc.tableGroupsName.company.name,
                    activeName: ConstantsSvc.tableGroupsName.company.alternative,
                    serviceAction: FilterService.getResultByCompany,
                    exportTable: DownloadService.downloadCompanies,
                    changeFilterForCharts: $scope.changeFilterForCharts.company,
                    makeChart: ChartService.trendView.byCompanies,
                    route: TableGroupsRoutes.companies
                },
                {
                    name: ConstantsSvc.tableGroupsName.drugClass.name,
                    activeName: ConstantsSvc.tableGroupsName.drugClass.alternative,
                    serviceAction: FilterService.getResultByDrugClasses,
                    exportTable: DownloadService.downloadDrugClasses,
                    changeFilterForCharts: $scope.changeFilterForCharts.drugClasses,
                    makeChart: ChartService.trendView.byDrugClasses,
                    route: TableGroupsRoutes.drugClasses
                },
                {
                    name: ConstantsSvc.tableGroupsName.molecule.name,
                    activeName: ConstantsSvc.tableGroupsName.molecule.alternative,
                    serviceAction: FilterService.getResultByMolecule,
                    exportTable: DownloadService.downloadMolecule,
                    changeFilterForCharts: $scope.changeFilterForCharts.molecule,
                    makeChart: ChartService.trendView.byMolecule,
                    route: TableGroupsRoutes.molecules
                },
                {
                    name: ConstantsSvc.tableGroupsName.target.name,
                    activeName: ConstantsSvc.tableGroupsName.target.alternative,
                    serviceAction: FilterService.getResultByTarget,
                    exportTable: DownloadService.downloadTarget,
                    changeFilterForCharts: $scope.changeFilterForCharts.target,
                    makeChart: ChartService.trendView.byTarget,
                    route: TableGroupsRoutes.targets
                },
                {
                    name: ConstantsSvc.tableGroupsName.drug.name,
                    activeName: ConstantsSvc.tableGroupsName.drug.alternative,
                    serviceAction: FilterService.getResultByDrug,
                    exportTable: DownloadService.downloadDrug,
                    changeFilterForCharts: $scope.changeFilterForCharts.drug,
                    makeChart: ChartService.trendView.byDrug,
                    route: TableGroupsRoutes.drugs
                },
                {
                    name: ConstantsSvc.tableGroupsName.route.name,
                    activeName: ConstantsSvc.tableGroupsName.route.alternative,
                    serviceAction: FilterService.getResultByRoute,
                    exportTable: DownloadService.downloadRoute,
                    changeFilterForCharts: $scope.changeFilterForCharts.route,
                    makeChart: ChartService.trendView.byRoute,
                    route: TableGroupsRoutes.routes
                }
            ]
        };
    }

    function setDropdownSources($scope, ConstantsSvc, makeBarsChart, addToComparison, toggleGroup) {
        $scope.actionDropdown = [
            {text: 'Make chart', isGroup: true},
            {text: 'Chart View', onClick: () => makeBarsChart()},
            {text: 'Trends View', onClick: () => $scope.showTrendsVewProfile()},
        ];

        $scope.actionComparisonDropdown = [
            ...$scope.actionDropdown,
            {text: 'Comparison', isGroup: true},
            {text: 'Add to Comparison', onClick: () => addToComparison()}
        ];

        $scope.groupDropdown = [
            getAllItemsGroup($scope, ConstantsSvc, toggleGroup),

            {text: 'Basic', subData: getGeneralGroups($scope, ConstantsSvc, toggleGroup)},

            {text: 'Advanced', subData: getAdvancedGroups($scope, ConstantsSvc, toggleGroup)}
        ];
    }

    function getAllItemsGroup($scope, ConstantsSvc, toggleGroup) {
        const [allItemsGroup] = getGroupsByNames($scope.groups.source, [ConstantsSvc.tableGroupsName.allData.name], toggleGroup);

        return allItemsGroup;
    }

    function getAdvancedGroups($scope, ConstantsSvc, toggleGroup) {
        const advancedGroupNames = [
            ConstantsSvc.tableGroupsName.molecule.name,
            ConstantsSvc.tableGroupsName.target.name,
            ConstantsSvc.tableGroupsName.drug.name,
            ConstantsSvc.tableGroupsName.route.name
        ];

        return getGroupsByNames($scope.groups.source, advancedGroupNames, toggleGroup);
    }

    function getGeneralGroups($scope, ConstantsSvc, toggleGroup) {
        const generalGroupNames = [
            ConstantsSvc.tableGroupsName.diseaseGroup.name,
            ConstantsSvc.tableGroupsName.subDiseaseGroup.name,
            ConstantsSvc.tableGroupsName.indication.name,
            ConstantsSvc.tableGroupsName.company.name,
            ConstantsSvc.tableGroupsName.drugClass.name
        ];

        return getGroupsByNames($scope.groups.source, generalGroupNames, toggleGroup);
    }

    function getGroupsByNames(source, names, toggleGroup) {
        return source
            .filter(x => names.indexOf(x.name) > -1)
            .map(x => mapActionGroupItem(x, toggleGroup));
    }

    function mapActionGroupItem(item, toggleGroup) {
        return {
            text: item.name,
            onClick: () => toggleGroup(item)
        };
    }
})();
